import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    { path: '/', name: 'home', component: HomeView },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import('../views/NotFoundView.vue') },
    { path : '/competences', name: 'competences', component: () => import('../views/CompetencesView.vue') },
    { path : '/map', name: 'map', component: () => import('../views/MapView.vue') },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
