<script setup>
import {ref} from 'vue'
import htmlImg from '@/assets/images/html.svg'
import phpImg from '@/assets/images/php.svg'
import sqlImg from '@/assets/images/sql.svg'
import vuejsImg from '@/assets/images/vuejs.png'
import reactImg from '@/assets/images/react.svg'
import laravelImg from '@/assets/images/laravel.svg'
import jsImg from '@/assets/images/js.png'
import cssImg from '@/assets/images/css.png'
import tsImg from '@/assets/images/ts.png'
import csharpImg from '@/assets/images/c.svg'
import sassImg from '@/assets/images/sass.svg'
import flutterImg from '@/assets/images/flutter.svg'

const data = ref({
  labels: [
    'HTML',
    'PHP',
    'SQL',
    'VueJS',
    'ReactJS',
    'Laravel',
    'JavaScript',
    'CSS',
    'TypeScript',
    'C#',
    'SCSS',
    'Flutter'
  ],
  img: [
    htmlImg,
    phpImg,
    sqlImg,
    vuejsImg,
    reactImg,
    laravelImg,
    jsImg,
    cssImg,
    tsImg,
    csharpImg,
    sassImg,
    flutterImg
  ],
  skills: [
    'Expérimentée',
    'Expérimentée',
    'Expérimentée',
    'Expérimentée',
    'Intermediaire',
    'Expérimentée',
    'Expérimentée',
    'Expérimentée',
    'Expérimentée',
    'Intermediaire',
    'Expérimentée',
    'Débutante',
  ]
})
</script>

<template>
  <div class="inner-scroller" aria-hidden="true">
    <div class="scrollerWrapper" role="region" >
      <v-banner
          v-for="(label, index) in data.labels"
          :key="label"
          lines="one" scroller
          :stacked="false"
          class="scroller"
      >
        <div class="scroller" id="tech-${index}">
          <img  loading="lazy" :src="data.img[index]"  :alt="`${label} logo`" />
          <span>
            <p>{{ data.labels[index] }}</p>
            <p>{{ data.skills[index] }}</p>
          </span>
        </div>
      </v-banner>
    </div>
  </div>
</template>

<style scoped>
.inner-scroller {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  @media (max-width: 768px) {
    mask: none;
  }
}

.scrollerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  height: max-content;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.scrollerWrapper span {
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: -1000;
  position: absolute;
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  left: 0;
  bottom: 0;
  transition: opacity 1s;

  & p {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: white;
    padding: 5px;
    font-size: 1rem;
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    z-index: -1000;
  }
}

.scrollerWrapper img {
  margin: 0 10px;
  filter: grayscale(100%);
  transition: filter 1s;
  @media (max-width: 768px) {
    margin: 0;
    width: 15px;
    height: auto;
  }
}

.scrollerWrapper .scroller:hover {
  & img {
    filter: grayscale(0);
  }

  & span {
    z-index: 1000;
    opacity: 100;
  }
}

.scroller {
  width: fit-content;
  background-color: #111111;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0;
  }
}
</style>
